// TermsModal.jsx
import React from 'react';
import Modal from 'react-modal';
import ModalService from 'services/Modal';

const TermsModal = ({ isOpen, onAccept }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onAccept}
    shouldCloseOnOverlayClick={false} // Disable closing by clicking outside
    shouldCloseOnEsc={false}          // Disable closing with Esc key
    style={{
      ...ModalService.STYLE.content,
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        color: "#737373",
        backgroundColor: '#f2f2f2',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        textAlign: 'left',
      },
      overlay: { backgroundColor: 'transparent' }, // No overlay color
    }}
    ariaHideApp={false}
  >
    <div className="jumbotron" style={{ margin: 0 }}>
      <p>
        filmservice.net is a service offered by filmservice.net AG.
        Bookings made through this platform are carried out and billed
        within the framework of a contractual relationship with filmservice.net AG.
      </p>
      <p>
        We are here to provide you with the perfect service.
        Should you have any questions or require any support,
        feel free to call us at +41 76 468 81 01.
      </p>
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <button
          className="btn btn-success"
          onClick={onAccept}
        >
          OK
        </button>
      </div>
    </div>
  </Modal>
);

export default TermsModal;
