import React from 'react';
import Waypoint from 'react-waypoint';
import moment from 'moment/moment';

import Errors from 'services/Errors';
import Cache from 'services/Cache';
import Form from 'services/Form';
import I18n from 'services/I18n';
import Query from 'services/Query';

import App from 'components/App';
import Utilities from 'components/Films/Utilities';
import Playlists from 'components/Films/Playlists';

import Edit from './Edit';

const $ = require('jquery');
require('jquery-ui/ui/widgets/sortable');

const throttle = require('lodash/throttle')
const t = I18n.namespace('transfers');

const DISTRIBUTOR_ROLES = [
  'super_admin',
  'admin',
  'distributor_supervisor',
  'distributor_booker',
  'distributor_homevideo_responsible',
]

export default class Transfers extends React.Component {

  constructor(props) {
    super(props)

    this.page = 0;
    this.form = new Form(this);

    this.state = {
      transfers: [],
      key: Math.random(),
    };

    this.playlistsRow = null;
    this.startPosition = null;

    this.load = this.load.bind(this);
    this.more = this.more.bind(this);
    this.startSorting = throttle(this.startSorting.bind(this));
    this.sortElement = throttle(this.sortElement.bind(this));
    this.setTransfer = this.setTransfer.bind(this);

    this.exportTransfers = this.exportTransfers.bind(this);
    this.exportFromDate = React.createRef();
    this.exportToDate = React.createRef();

    this.forceUpdate = this.forceUpdate.bind(this);
    this.lastLocation = Query.encode(Query.get());
    this.throttleUpdate = throttle(this.forceUpdate, 1000, {leading: false});
  }

  componentDidMount() {
    App.setTitle('Transfers');

    this.setState({...Cache.get('transfes')});

    this.load().then(() => {
      this.props.api.get('/transfers').then((data) => {
        this.setState(data.data, () => {
          this.forceUpdate();
        });

        Cache.set('transfes', data.data);
        return data;
      });
    });

    this.unlisten = this.props.history.listen(() => {
      window.setTimeout(
        () => {
          let query = Query.get();
          delete query.editTransfer;

          let newLocation = Query.encode(query);
          if(this.lastLocation == newLocation) {
            this.forceUpdate();
            return ;
          }

          this.lastLocation = newLocation;
          this.page = 0;
          this.load();
        },
        100,
      );
    });

    App.instance.socketio.on('transfer', (message) => {
      let transferIndex = this.state.transfers.findIndex((transfer) => {
        return transfer.id == message.id;
      });

      if(transferIndex === null) {
        return ;
      }

      this.state.transfers[transferIndex] = {
        ...this.state.transfers[transferIndex],
        ...message,
      };

      this.throttleUpdate();
    });
  }

  componentWillUnmount() {
    this.unlisten();
    App.instance.socketio.removeAllListeners('transfer');
    this.unmounted = true;
  }

  transfersTable() {
    return $('.table--transfer tbody:first').first();
  }

  createSortHelper(_event, tr) {
    this.playlistsRow = $(tr).next().detach();
    this.startPosition = $(tr).next().attr('data-position');

    let originals = $(
      '.table-row--transfer' +
        ':not(.ui-sortable-helper)' +
        ':not(.table-row--head)' +
        ':first ' +
        'td'
    );

    let helper = tr.clone();
    let lastIndex = helper.children().length - 1;
    helper.children().each(function(index) {
      let width = originals.eq(index).get(0).clientWidth;

      // wrong displayed table tr width workaround
      if(index == lastIndex) {
        width = 700;
      }

      $(this).width(width + 'px');
    });

    return helper;
  }

  startSorting(event, ui) {
    ui.placeholder.height(ui.helper.get(0).clientHeight);
  }

  sortElement(_event, ui) {
    this
      .transfersTable()
      .find('.table-row--transfer--sm:not(.not-sortable)')
      .removeClass('hidden');

    let item = $(ui.item);
    if(item.next().hasClass('table-row--transfer--sm')) {
      item.before(item.next());
    }

    $(ui.item).after(this.playlistsRow);
    this.playlistsRow = null;

    if(item.next().attr('data-position') == this.startPosition) {
      return ;
    }

    let next = item.next().next();
    let isAfter = !next.hasClass('not-sortable');
    let isBefore = !item.prev().hasClass('not-sortable');

    let data = {
      id: item.attr('data-id'),
      organization_id: Query.get().organization_id,
    };

    if(next.get(0) && isAfter) {
      data.type = 'after';
      data.target_id = next.attr('data-id');
    } else if(item.prev().get(0) && isBefore) {
      data.type = 'before';
      data.target_id = item.prev().attr('data-id');
    } else {
      throw new Error('no next or prev element for sorting');
    }

    this
      .props
      .api
      .post('/transfers/sort', data)
      .then(() => {
        this.page = 0;
        this.load(false, true);
      })
      .catch(Errors.alertHandler('transfer sort failed'));
  }

  load(more, reset) {
    if(this.unmounted) {
      return ;
    }

    this.forceUpdate();

    let query = {...Query.get(), page: this.page, foyer: false};

    if(query.organization_id && query.organization_id.split(',').length == 1) {
      query.order = 'position';
    }

    let promise =
      this
        .form
        .api
        .get('/transfers/list', query, {noFooter: true})
        .then((response) => {
          let state = {
            ...response.data,
            transfers: this.sortTransfers(response.data.transfers),
            ready: true,
          };

          if(more) {
            state.transfers = [...this.state.transfers, ...state.transfers];
          }

          if(reset) {
            state.key = Math.random();
          }

          this.setState(state);
          this.forceUpdate();
        })
        .catch(this.form.api.error);

    return promise;
  }

  more() {
    if(this.state.loading) {
      return ;
    }

    this.page++;
    this.load(true);
  }

  sortTransfers(transfers) {
    let organizationId = Query.get('organization_id');
    if(organizationId && organizationId.split(',').length == 1) {
      return transfers;
    }

    let result = transfers.sort((first, second) => {
      let firstMoment = moment(
        moment(first.created_at).format('YYYY-MM-DD HH:mm'),
      );

      let secondMoment = moment(
        moment(second.created_at).format('YYYY-MM-DD HH:mm'),
      );

      if(firstMoment.isAfter(secondMoment)) {
        return 1;
      }

      if(firstMoment.isBefore(secondMoment)) {
        return -1;
      }

      if(first.organization_city_name && second.organization_city_name) {
        if(first.organization_city_name > second.organization_city_name) {
          return -1;
        }

        if(first.organization_city_name < second.organization_city_name) {
          return 1;
        }
      }

      if(first.position > second.position) {
        return 1;
      }

      if(first.position < second.position) {
        return -1;
      }

      return 0;
    });

    return result.reverse();
  }

  shouldComponentUpdate() {
    return false;
  }

  setTransfer(transfer) {
    let transfers = this.state.transfers.map((localTransfer) => {
      if(localTransfer.id == transfer.id) {
        return {...localTransfer, ...transfer};
      }

      return localTransfer;
    });

    this.setState({transfers: transfers});
  }

  exportTransfers() {
    let exportDates = {
      from: this.exportFromDate.current.value,
      to: this.exportToDate.current.value,
    };

    let url = '/reports/transfers';

    if (Object.values(exportDates).every(Boolean)) {
      url += `?${$.param(exportDates)}`;
    }

    this
      .props
      .api
      .get(
        url,
        {}, { axios: { responseType: 'arraybuffer' }}
      )
      .then(({data}) => {
        let file = new Blob([data], { type: 'text/csv' });
        let url = URL.createObjectURL(file);

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'transfers.csv');

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(Errors.alertHandler('download failed'));
  }

  render() {
    return (
      <div className={
        (this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') ?
          'transfers--admin--list' :
          undefined
      }>
        {Query.get('editTransfer') && (
          <Edit
            api={this.props.api}
            transferId={Query.get('editTransfer')}
            reload={this.load}
            onChange={this.setTransfer}
          />
        )}

        {this.renderFilter(Query.get())}
        {this.renderTransfers(Query.get())}
      </div>
    );
  }

  setter(key) {
    return this.form.callback(key, (value) => {
      if(value instanceof Array) {
        value = value.join(',');
      }

      if(!value) {
        value = undefined;
      }

      this.page = 0;
      Query.set(key, value);
      Cache.set('transfers.query', Query.get());

      if(key == 'distributor_id') {
        Cache.set('/films.query', {
          ...Cache.get('/films.query', {}),
          distributor_id: value || null,
         });
      }

      if(key == 'kinds') {
        Cache.set('/films.query', {
          ...Cache.get('/films.query', {}),
          kinds: value,
        });
      }
    });
  }

  renderFilter(query) {
    return (
      <section className="section-filterbar filterbar container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <form action="" className="form-inline transfers__search-form">
              <div className="filterbar-dropdown form-group">
                {this.form.fields.select(
                  this.state.distributors &&
                    this.state.distributors.length > 0 &&
                    'distributor_id',
                  null,
                  {
                    valuesObjects: this.state.distributors,
                    blank: t('all_distributors'),
                    value:
                      query.distributor_id && parseInt(query.distributor_id),
                    onChange: this.setter('distributor_id'),
                    disabled: false,
                    element: {
                      className: `
                        custom-select
                        filterbar-select
                        transfers__distributors
                      `,
                    },
                  },
                )}
              </div>

              {Utilities.contentKindCheckboxes()}

              {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && (
                <div className="filterbar-dropdown form-group">
                  {this.form.fields.select('status', null, {
                    values: this.form.const('status.list', [
                      ['delivered', 'Completed'],
                      ['in_progress', 'In Progress'],
                      ['prepared', 'Not yet started'],
                      ['offline', 'Offline'],
                      ['paused', 'Paused'],
                      ['cancelled', 'Cancelled'],
                      ['failed', 'Failed'],
                      [
                        'prepared,in_progress,paused,failed,offline',
                        'Not Completed',
                      ],
                    ]),
                    value: query.status,
                    onChange: this.setter('status'),
                    disabled: false,
                    blank: 'All Statuses',
                    element: this.form.const('status.element', {
                      className: `
                        custom-select
                        filterbar-select
                        transfers__filter-status
                      `,
                    }),
                  })}
                </div>
              )}

              {this.props.currentUser.role == 'admin' && (
                <div className="filterbar-dropdown form-group">
                  {this.form.fields.select('type', null, {
                    values: this.form.const('type.list', [
                      ['SendDisk', 'Disk'],
                      ['FtpPull', 'FTP'],
                      ['WebTransfer', 'Web'],
                      ['MovietransitTransfer', 'MovieTransit'],
                      ['GofilexTransfer', 'Gofilex'],
                    ].filter((type) => {
                      let transferVisibilites = this.props.currentUser.transfer_visibilities;

                      if (!transferVisibilites) return true;

                      return transferVisibilites.includes(type[0])
                    })),
                    value: query.type,
                    onChange: this.setter('type'),
                    disabled: false,
                    blank: 'All Modes',
                    element: this.form.const('type.element', {
                      className: `
                        custom-select
                        filterbar-select
                        transfers__filter-type
                      `,
                    }),
                  })}
                </div>
              )}

              {this.props.currentUser.role == 'super_admin' && (
                <div className="filterbar-dropdown form-group">
                  {this.form.fields.select('type', null, {
                    values: this.form.const('type.list', [
                      ['SendDisk', 'Disk'],
                      ['FtpPull', 'FTP'],
                      ['WebTransfer', 'Web'],
                      ['MovietransitTransfer', 'MovieTransit'],
                      ['GofilexTransfer', 'Gofilex'],
                    ]),
                    value: query.type,
                    onChange: this.setter('type'),
                    disabled: false,
                    blank: 'All Modes',
                    element: this.form.const('type.element', {
                      className: `
                          custom-select
                          filterbar-select
                          transfers__filter-type
                        `,
                    }),
                  })}
                </div>
              )}

              {(this.props.currentUser.role == 'admin') && (
                <div className="filterbar-dropdown form-group">
                  {this.form.fields.select('date', null, {
                    values: [
                      ['due_date_met', 'Due date met'],
                      ['due_date_not_met', 'Due date not met'],
                    ],
                    value: query.date,
                    onChange: this.setter('date'),
                    disabled: false,
                    blank: 'All Dates',
                    element: this.form.const('date.element', {
                      className: `
                        custom-select
                        filterbar-select
                        transfers__filter-date
                      `,
                    }),
                  })}
                </div>
              )}

              {DISTRIBUTOR_ROLES.includes(this.props.currentUser.role) && (
                <div
                  id="transfers__film-id-container"
                  className="
                    filterbar-dropdown
                    form-group
                    transfers__filter-container
                    transfers__films-filter-container
                  "
                >
                  {
                    this.state.films &&
                      this.form.fields.dropdown('film_id', null, {
                        valuesObjects: this.form.const(
                          `films_${query.distributor_id}`,
                          this.state.films.filter((film) => {
                            if(!query.distributor_id) {
                              return true;
                            }

                            if(film.organization_id == query.distributor_id) {
                              return true;
                            }

                            if(!film.organizations) {
                              return false;
                            }

                            let organizationFound =
                              film.organizations.find((organization) => {
                                return organization.id == query.distributor_id;
                              });

                            if(organizationFound) {
                              return true;
                            }

                            return false;
                          }),
                        ),
                        value: query.film_id ? query.film_id.split(',') : [],
                        onChange: this.setter('film_id'),
                        disabled: false,
                        blank: 'Film',
                        element: this.form.const('film_id.element', {
                          options: {
                            placeholder: 'Film',
                          },
                          className: `
                            custom-select
                            filterbar-select
                            transfers__films-filter
                          `,
                          style: {
                            width: '5px',
                          },
                          ref: () => {
                            document
                              .querySelector(`
                                #transfers__film-id-container
                                .select2-container
                                input
                              `)
                              .style
                              .width = '100px';
                          },
                        }),
                      })
                  }

                  {query.film_id && (
                    <a
                      href="#"
                      onClick={
                        this.form.callback('reset_film_id', () => {
                          Query.set('film_id', undefined);
                        })
                      }
                      className="
                        fa
                        fa-times
                        transfers__clear-filter
                        transfers__clear-films-filter
                      "
                    ></a>
                  )}
                </div>
              )}

              {DISTRIBUTOR_ROLES.includes(this.props.currentUser.role) && (
                <div
                  id="transfers__organization-id-container"
                  className="
                    filterbar-dropdown
                    form-group
                    transfers__filter-container
                    transfers__films-filter-container
                  "
                >
                  {
                    this.state.cinemas &&
                      this.form.fields.dropdown('organization_id', null, {
                        valuesObjects: this.state.cinemas || [],
                        value:
                          query.organization_id ?
                            query.organization_id.split(',') :
                            [],
                        onChange: this.setter('organization_id'),
                        disabled: false,
                        blank: 'Site',
                        element: this.form.const('organization_id.element', {
                          options: {
                            placeholder: 'Site',
                          },
                          className: `
                            custom-select
                            filterbar-select
                            transfers__organizations-filter
                          `,
                          style: {
                            width: '5px',
                          },
                          ref: () => {
                            document
                              .querySelector(`
                                #transfers__organization-id-container
                                .select2-container
                                input
                              `)
                              .style
                              .width = '100px';
                          },
                        }),
                      })
                  }

                  {query.organization_id && (
                    <a
                      href="#"
                      onClick={
                        this.form.callback('reset_organization_id', () => {
                          Query.set('organization_id', undefined);
                        })
                      }
                      className="
                        fa
                        fa-times
                        transfers__clear-filter
                        transfers__clear-organizations-filter
                      "
                    ></a>
                  )}
                </div>
              )}

              {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && (
                <div className="filterbar__download-report form-group">
                  <span className="filterbar__download-report-label">
                    Export
                  </span>
                   <input
                    className="filterbar__download-report-date-picker"
                    type="date"
                    ref={this.exportFromDate}
                  />
                  <input
                    className="filterbar__download-report-date-picker"
                    type="date"
                    ref={this.exportToDate}
                  />
                  <a
                    href="#"
                    onClick={this.exportTransfers}
                    className="btn"
                    title="Export Transfers"
                  >
                    <span className="fa fa-download"></span>
                  </a>
                </div>
              )}

              {Object.keys(query).length != 0 && (
                <div
                  className="
                    filterbar-dropdown
                    form-group
                  "
                >
                  <a
                    href="#"
                    onClick={
                      this.form.callback('reset_query', (event) => {
                        event.preventDefault();

                        Cache.set('/films.query', {
                          ...Cache.get('/films.query', {}),
                          distributor_id: undefined,
                          kinds: undefined,
                        });

                        Query.set({});
                      })
                    }
                    style={{
                      color: 'white',
                      fontSize: '11.5px',
                    }}
                    className="
                      btn
                      btn-primary
                    "
                  >Reset</a>
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    );
  }

  renderTransfers(query) {
    let role = this.props.currentUser.role;

    return (
      <section className="section-transfer container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <div className="table-stack">
              <table className="table table--transfer">
                <thead>
                  <tr className="table-row--transfer table-row--head">
                    <th></th>
                    <th>Progress</th>

                    {(role == 'admin' || role == 'super_admin') && (
                      <th>Ordered</th>
                    )}

                    {(role == 'admin' || role == 'super_admin') && (
                      <th>Started</th>
                    )}

                    <th>Completion</th>

                    {DISTRIBUTOR_ROLES.includes(role) && (
                      <th>Due Date</th>
                    )}

                    <th>User</th>
                    <th>Site</th>
                    <th className="table-heading--transfer-cpl--lg">CPL</th>
                  </tr>
                </thead>

                <tbody key={this.state.key}>
                  {this.state.transfers.map((transfer) => {
                    return [
                      this.renderTransfer(transfer, query),
                      this.renderTransferTail(transfer),
                    ];
                  })}

                  <tr>
                    <td>
                      {this.state.ready && (
                        <Waypoint
                          scrollableAncestor={
                            document.getElementsByClassName('content')[0]
                          }
                          onEnter={this.more}
                          bottomOffset="-512px"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }

  isTransferLocked(transfer) {
    return transfer.status_cd == 3 || transfer.status_cd == 4;
  }

  roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  renderTransfer(transfer, query) {
    const { role } = this.props.currentUser;
    const isAdmin = role === 'admin' || role === 'super_admin';
    const isDelivered = transfer.type == "FtpPull" || transfer.status == 'delivered';
    const fractionDisplay = transfer.fraction && transfer.fraction > 0 && transfer.fraction !== 100 ? this.roundToTwo(transfer.fraction) : parseInt(transfer.fraction || '0');
    const speedDisplay = typeof transfer.speed === 'string' ? transfer.speed : `${Math.round((transfer.speed || 0) / 1000 / 1000)} Mbit/s`;

    let progress = (<span>
      {!isAdmin && isDelivered ? (
        <span>100%</span>
      ) : (
        <span>{fractionDisplay}%</span>
      )}

      { ' ' }

      {isAdmin && (<span>{speedDisplay}</span>)}
    </span>)
    return (
      <tr
        data-id={transfer.id}
        data-position={transfer.position}
        key="transfer"
        className={
          'table-row--transfer ' +
            this.transferClasses(transfer) + ' ' +
            (transfer.hidden && 'table-row--transfer-hidden')
        }
      >
        <td
          className={
            'table-cell--transfer table-cell--transfer-status ' + (
              !this.isTransferLocked(transfer) &&
                transfer.type == 'ApiTransfer' &&
                query.organization_id &&
                query.organization_id.split(',').length == 1 &&
                'table-cell--transfer-status--compact'
            )
          }
        >
          <a
            href={(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') ? '#' : undefined}
            className="transfer-icon"
            onClick={
              this.form.callback(
                `transfers.${transfer.id}.edit`,
                () => {
                  if(this.props.currentUser.role !== 'admin' && this.props.currentUser.role !== 'super_admin') {
                    return ;
                  }

                  Query.set('editTransfer', transfer.id)
                },
              )
            }
          >
            <i
              title={
                ((!isAdmin && isDelivered && 'delivered') || transfer.movietransit_status || transfer.gofilex_status || transfer.status) +
                ((this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && transfer.gofilex_order_id ?
                  ' (#' + (transfer.id) + ' - ' + transfer.gofilex_order_id + ')' :
                  ' (#' + (transfer.id) + ')') +
                  (
                    transfer.eden_order_transfer &&
                      transfer.eden_order_transfer.is_cancelled ?
                      ' (cancelled by eden)' :
                      ''
                  ) +

                  (
                    transfer.ofe_order_transfer &&
                      transfer.ofe_order_transfer.ofe_order.is_cancelled ?
                      ' (cancelled by ofe)' :
                      ''
                  )
              }
              className={
                'icon icon-lg fa-fw ' +
                  ((isDelivered) ? Utilities.getTransferIconClass(3) :
                    Utilities.getTransferIconClass(transfer.status_cd))
              }
            ></i>
          </a>

          {/* Admins should see all icons, other roles should see only SendDisk icon */}
          {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') ? (
            <a
              href={transfer.tracking_url ? transfer.tracking_url : undefined}
              target="_blank"
              rel="nohistory"
              className={
                'transfers__type-icon icon icon-lg fa-fw ' +
                Utilities.getTransferTypeIconClass(transfer.type)
              }
              title={transfer.type}
            ></a>
          ) : (
            <span>
              {(transfer.type == 'SendDisk') && (
                <a
                  href={transfer.tracking_url ? transfer.tracking_url : undefined}
                  target="_blank"
                  rel="nohistory"
                  className={
                    'transfers__type-icon icon icon-lg fa-fw ' +
                    Utilities.getTransferTypeIconClass(transfer.type)
                  }
                  title={transfer.type}
                ></a>
              )}
            </span>
          )}



        </td>

        <td
          title={(transfer.size) ? (transfer.size / 1024.0 / 1024.0 / 1024.0).toFixed(1) + ' GB' : null}
          className={
            `
              table-cell--transfer
              table-cell--transfer-progress
            ` +
            (this.props.currentUser.role != 'admin' && this.props.currentUser.role != 'super_admin' &&
              'table-cell--transfer-progress-compact ')
          }
        >
          {((this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && transfer.gofilex_order_id) ? (
            <a target="_blank" rel="nohistory" href={`https://portal.gofilex.com/orders/${transfer.gofilex_order_id}`} style={{ color: 'rgb(115, 115, 115)'}}>
              {progress}
            </a>
          ) : progress}
        </td>

        {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && (
          <td className="table-cell--transfer-ordered">
            {I18n.datetime(
              (
                transfer.hidden ?
                  transfer.hidden_prepared_date :
                  transfer.prepared_date
              ) || transfer.created_at
            )}
          </td>
        )}

        {(this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') && (
          <td className="table-cell--transfer-started">
            {I18n.datetime(
              transfer.hidden ?
                transfer.hidden_in_progress_date :
                transfer.in_progress_date
            )}
          </td>
        )}

        <td
          className={
            `
              table-cell--transfer
              table-cell--transfer-completion
            ` +
            ((this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') &&
              'table-cell--transfer-completion--compact')
          }
          title={
            (this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') ?
              (
                `Ordered: ${I18n.datetime(
                  (
                    transfer.hidden ?
                      transfer.hidden_prepared_date :
                      transfer.prepared_date
                  ) || transfer.created_at,
                )}\n` +
                `Started: ${I18n.datetime(
                  transfer.hidden ?
                    transfer.hidden_in_progress_date :
                    transfer.in_progress_date
                )}\n` +
                (
                  transfer.due_date ?
                    `Due date: ${I18n.datetime(transfer.due_date)}` :
                    ''
                )
              ) :
              undefined
          }
          style={{
            color:
              (this.props.currentUser.role == 'admin' || this.props.currentUser.role == 'super_admin') &&
                moment(transfer.estimation).isAfter(
                  moment(transfer.due_date)
                ) &&
                'red',
          }}
        >
          {I18n.datetime(
            !isAdmin && transfer.type == "FtpPull" ? transfer.created_at :
            (transfer.estimation || (
              transfer.hidden ?
                transfer.hidden_delivered_date :
                transfer.delivered_date
            ))
          )}
        </td>

        {DISTRIBUTOR_ROLES.includes(this.props.currentUser.role) && (
          <td className="table-cell--transfer-duedate">
            {I18n.datetime(transfer.due_date)}
          </td>
        )}

        <td className="table-cell--transfer-user">
          <a
            href="#"
            onClick={
              this.form.callback(
                `transfers.${transfer.id}_filter_by_user`,
                () => {
                  Query.set('organization_id', undefined);
                  Query.set('user_id', transfer.user_id);
                },
              )
            }
            className="name link-grey"
            title={
              (transfer.ip || '') +
                ' (' +
                (
                  transfer.hidden ?
                    transfer.hidden_username :
                    transfer.username
                ) +
                ')'
            }
          >
            {
              transfer.hidden ?
                transfer.hidden_user_email :
                transfer.user_email
            }
          </a>
        </td>

        <td className="table-cell--transfer-user-info">
          <a
            href="#"
            onClick={
              this.form.callback(
                `transfers.${transfer.id}_filter_by_organization`,
                () => {
                  Query.set('user_id', undefined);
                  Query.set('organization_id', transfer.organization_id);
                },
              )
            }
            className="organization link-grey"
            title={transfer.ip}
          >
            {transfer.organization_name}
          </a>
        </td>

        <td
          className="
            table-cell--transfer-cpl
            table-cell--transfer-cpl--lg
          "
          colSpan="4"
        >
          {this.renderPlaylists(transfer)}
        </td>
      </tr>
    );
  }

  renderTransferTail(transfer) {
    return (
      <tr
        data-id={transfer.id}
        data-position={transfer.position}
        key="tail"
        className={
          'table-row--transfer table-row--transfer--sm ' +
            this.transferClasses(transfer) + ' ' +
            (transfer.hidden && 'table-row--transfer-hidden')
        }
      >
        <td></td>
        <td
          colSpan="7"
          className="
            table-cell--transfer-cpl
            table-cell--transfer-cpl--sm
          "
        >
          <div className="table-cell--transfer-playlists">
            {this.renderPlaylists(transfer)}
          </div>
        </td>
      </tr>
    );
  }

  transferClasses(transfer) {
    return (
      (transfer.sorted_playlists.length > 1 && 'table-row--transfer-group') +
      ' ' +
      (transfer.status == 'delivered' && 'green-i') +
      ' ' +
      ((this.isTransferLocked(transfer) || transfer.type != 'ApiTransfer') &&
        'not-sortable')
    );
  }

  renderPlaylists(transfer) {
    return (
      <div>
        {
          transfer.sorted_playlists.map((playlist) => {
            return (
              <div key={playlist.id}>
                <a
                  className="grey"
                  href={'/films/' + playlist.film_id}
                >
                  {Playlists.renderTitle('cinema', playlist, this.props.currentUser.role)}
                </a>
              </div>
            );
          })
        }

        {
          (
            transfer.sorted_playlists.length == 0 &&
              transfer.eden_order_transfer &&
              transfer.eden_order_transfer.annotation_text && (
                <div className="grey">
                  {transfer.eden_order_transfer.annotation_text}
                </div>
              )
          )
        }

        {
          (
            transfer.sorted_playlists.length == 0 &&
              transfer.ofe_order_transfer &&
              transfer.ofe_order_transfer.description && (
                <div className="grey">
                  {transfer.ofe_order_transfer.description}
                </div>
              )
          )
        }
      </div>
    );
  }

}
