import React from 'react';

import App from 'components/App';

import Index from 'components/Index';

import ArchiveDisksList from 'components/ArchiveDisks/List';
import ArchiveDisksCreate from 'components/ArchiveDisks/Create';
import ArchiveDisksEdit from 'components/ArchiveDisks/Edit';

import Contact from 'components/Contact';
import Confirm from 'components/Confirm';

import DisksList from 'components/Disks/List';
import DisksCreate from 'components/Disks/Create';
import DisksEdit from 'components/Disks/Edit';

import FilmsList from 'components/Films/List';
import FilmsSearch from 'components/Films/Search';
import FilmsShow from 'components/Films/Show';
import FilmsCreate from 'components/Films/Create';
import FilmsEdit from 'components/Films/Edit';
import FilmsIngest from 'components/Films/Ingest';
import FilmsNotification from 'components/Films/Notification';

import PlaylistsEdit from 'components/Playlists/Edit';
import PlaylistsCreate from 'components/Playlists/Create';
import PlaylistsDeliver from 'components/Playlists/Deliver';
import PlaylistsDeleteDcps from 'components/Playlists/DeleteDcps';

import OrganizationsList from 'components/Organizations/List';
import OrganizationsEdit from 'components/Organizations/Edit';
import OrganizationsCreate from 'components/Organizations/Create';
import OrganizationsTargets from 'components/Organizations/Targets';
import OrganizationsMap from 'components/Organizations/Map';

import StorageList from 'components/Storage/List';

import Profile from 'components/Profile';

import SearchHistoriesList from 'components/SearchHistories/List';

import ScreenersCreate from 'components/Screeners/Create';
import ScreenersCreateUser from 'components/Screeners/CreateUser';
import ScreenersList from 'components/Screeners/List';
import ScreenersShow from 'components/Screeners/Show';

import SuisaHistoriesList from 'components/SuisaHistories/List';

import Screens from 'components/Screens/List';
import ScreensEdit from 'components/Screens/Edit';
import ScreensCreate from 'components/Screens/Create';

import TargetsList from 'components/Targets/List';
import TargetsCreate from 'components/Targets/Create';
import TargetsEdit from 'components/Targets/Edit';

import TransfersList from 'components/Transfers/List';

import UsersList from 'components/Users/List';
import UsersEdit from 'components/Users/Edit';
import UsersLogin from 'components/Users/Login';

class Reload extends React.Component {

  componentDidMount() {
    window.location.reload();
  }

  render() {
    return null;
  }

}

export default class Router extends React.Component {

  static routes = [
    // root

    [['/'], Index],

    // archive_disks

    [['/archive_disks'], ArchiveDisksList],
    [['/archive_disks/create'], ArchiveDisksCreate],
    [['/archive_disks/(\\d+)', 'id'], ArchiveDisksEdit],

    // disks

    [['/disks'], DisksList],
    [['/disks/create'], DisksCreate],
    [['/disks/(\\d+)', 'id'], DisksEdit],

    // confirm

    [['/confirm'], Confirm],

    // contact

    [['/contact'], Contact],

    // films

    [['/films'], FilmsList],
    [['/films/create'], FilmsCreate],
    [['/films/delete_dcps'], PlaylistsDeleteDcps],
    [['/films/(\\d+)', 'id'], FilmsShow],
    [['/films/(\\d+\\.\\d+)', 'id'], FilmsShow],
    [['/films/(\\d+)/edit', 'id'], FilmsEdit],
    [['/films/(\\d+)/ingest', 'id'], FilmsIngest],
    [['/films/(\\d+)/playlists/create', 'filmId'], PlaylistsCreate],
    [['/films/(\\d+)/playlists/(\\d+)', 'filmId', 'id'], PlaylistsEdit],
    [['/films/(\\d+)/notification', 'filmId'], FilmsNotification],
    [['/films/delete_dcps'], PlaylistsDeleteDcps],

    [
      ['/films/(\\d+)/playlists/(\\d+)/screeners/new', 'filmId', 'playlistId'],
      ScreenersCreate,
    ],

    [
      [
        '/films/(\\d+)/playlists/(\\d+)/screeners/users/new',
        'filmId',
        'playlistId',
      ],
      ScreenersCreateUser,
    ],

    [
      ['/films/(\\d+)/playlists/([0-9a-f\\-]+)/deliver', 'filmId', 'id'],
      PlaylistsDeliver,
    ],

    [['/search'], FilmsSearch],

    // organizations

    [['/organizations'], OrganizationsList],
    [['/organizations/create'], OrganizationsCreate],
    [['/organizations/map'], OrganizationsMap],
    [['/organizations/(\\d+)', 'id'], OrganizationsEdit],
    [['/organizations/(\\d+)/targets', 'id'], OrganizationsTargets],

    // screens

    [['/organizations/(\\d+)/screens', 'organizationId'], Screens],

    [
      ['/organizations/(\\d+)/screens/create', 'organizationId'],
      ScreensCreate,
    ],

    [
      ['/organizations/(\\d+)/screens/(\\d+)', 'organizationId', 'id'],
      ScreensEdit,
    ],

    // profile

    [['/profile'], Profile],

    // storage
    [['/storage'], StorageList],

    // search & suisa histories

    [['/search_histories'], SearchHistoriesList],
    [['/suisa_histories'], SuisaHistoriesList],
    [['/api_check'], SuisaHistoriesList], // @todo removethis

    // screeners

    [['/screeners'], ScreenersList],
    [['/screeners/(\\d+)', 'filmId'], ScreenersShow],

    // targets

    [['/targets'], TargetsList],
    [['/targets/create'], TargetsCreate],
    [['/targets/(\\d+)', 'id'], TargetsEdit],

    // transfers

    [['/transfers'], TransfersList],

    // users

    [['/users'], UsersList],
    [['/users/list'], UsersList],
    [['/users/(\\d+)', 'id'], UsersEdit],
    [['/users/sign_in'], UsersLogin],
    [['/users/(\\d+)/sign_in', 'id'], UsersLogin],
    [['/users/sign_up'], Reload],
    [['/users/password/new'], Reload],
  ];

  constructor(props) {
    super(props);

    this.currentPath = null;

    this.state = {
      location: this.props.location,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({location: props.location});
  }

  componentDidMount() {
    this.currentPath = window.location.pathname;

    this.unlisten = this.props.history.listen((location) => {
      if(this.currentPath === window.location.pathname) {
        return ;
      }

      let state = {};
      if(location.state) {
        state = JSON.parse(location.state);
      }

      document.getElementsByClassName('content')[0].scrollTop = 0;
      document.getElementsByClassName('content')[0].focus();

      this.currentPath = window.location.pathname;
      App.instance.setState({footer: false, error: null, message: null});
      App.setTitle(null);
      App.instance.checkTerms();

      if (App.instance.state.user) {
        __insp.push(['identify', App.instance.state.user.email]);
      }

      this.setState({
        location: location.pathname,
        state: state,
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    if(!this.state.location) {
      return null;
    }

    let error = App.instance.state.error;
    if(error && error.response && error.response.status == 403) {
      return null;
    }

    let location = this.state.location;
    if(location.match(/\/$/) && location.length > 1) {
      location = location.substr(0, location.length - 1);
    }

    if(!this.props.currentUser && !location.startsWith('/users')) {
      return null;
    }

    for(let index = this.constructor.routes.length - 1; index >= 0; index--) {
      let route = this.constructor.routes[index];
      let match = location.match(new RegExp('^' + route[0][0] + '$'));
      if(match !== null) {
        return this.renderRoute(route, match);
      }
    }

    return (
      <div className="container">
        <h1>Error 404: not found</h1>

        <p>
          Page you are looking for does not exist;&nbsp;
          <a href="/">main page</a>.
        </p>
      </div>
    );
  }

  renderRoute(route, match) {
    let props = {...this.props};
    let index = 1;
    route[0].slice(1).forEach((key) => {
      props[key] = match[index];
      index += 1;
    });

    let Component = route[1];

    return (
      <Component
        key={window.location.pathname}
        {...props}
        {...this.state.state}
      />
    );
  }

}
